import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import toast from 'react-hot-toast';

import { deleteCookie, getCookie } from '../components/Entities/Lib/CookieWork';
import { handleRegisterPush } from '../notifications/Notification';

const onQueryStartedErrorToast = async (_, { queryFulfilled }) => {
    try {
        await queryFulfilled;
    } catch (error) {
        if (error.error.status === 401) {
            deleteCookie('token');
        }

        if (error.error.status === 500) {
            window.location.href = 'err';
            toast.error('Произошла ошибка на сервере');
        }

        if (getCookie('token') === 'undefined' || !getCookie('token')) {
            toast.error('Ошибка, требуется заново авторизоваться');
            window.location.href = 'login';
        }

        window.location.href = 'requests';
    }
};

const onQueryStartedErrorSettings = async (_, { queryFulfilled }) => {
    try {
        handleRegisterPush();
        await queryFulfilled;
    } catch (error) {
        console.error(error);
    }
};

export const sslApi = createApi({
    reducerPath: 'sslApi',
    refetchOnFocus: true,
    tagTypes: ['Requests', 'Ping'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api-erkon.tech/mobile/hs',
    }),

    endpoints: (builder) => ({
        getRequests: builder.query({
            query: (token) => ({
                url: `mobileProrab/orderlist`,
                providesTags: ['Requests'],
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: onQueryStartedErrorToast,
        }),

        getDeliveryList: builder.query({
            query: () => ({
                url: `mobileProrab/deliveryList`,
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getBonuses: builder.query({
            query: () => ({
                url: `mobileProrab/infoBonuses`,
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getCertificates: builder.query({
            query: () => ({
                url: `mobileProrab/sertificates`,
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getStatistic: builder.query({
            query: (information) => ({
                url: 'mobileProrab/infoCapacity',
                method: 'POST',
                body: information,
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getPopularNomenclatures: builder.query({
            query: (information) => ({
                url: 'mobileProrab/popularNomenclatures',
                method: 'POST',
                body: information,
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getSettings: builder.query({
            query: () => {
                if (getCookie('token') && getCookie('token') !== 'undefined') {
                    return {
                        url: `mobileProrab/getSettings`,
                        method: 'POST',
                        headers: {
                            token: getCookie('token'),
                        },
                    };
                }
            },
            onQueryStarted:
                getCookie('token') &&
                getCookie('token') !== 'undefined' &&
                onQueryStartedErrorSettings,
        }),

        addRequest: builder.mutation({
            query: () => {
                if (getCookie('token') && getCookie('token') !== 'undefined') {
                    return {
                        url: `mobileProrab/orderlist`,
                        method: 'POST',
                        headers: {
                            token: getCookie('token'),
                        },
                    };
                }
            },
            invalidatesTags: ['Requests'],
            onQueryStarted:
                getCookie('token') &&
                getCookie('token') !== 'undefined' &&
                onQueryStartedErrorToast,
        }),

        getPushList: builder.query({
            query: () => ({
                url: `mobileProrab/pushList`,
                body: {
                    deviceToken: localStorage.getItem('pushyToken'),
                },
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),
        createComplaint: builder.mutation({
            query: (orderUID, type, transits, content) => ({
                url: `mobileProrab/createComplaint`,
                body: {
                    orderUID: orderUID,
                    type: type,
                    transits: transits,
                    content: content,
                },
                headers: {
                    token: getCookie('token'),
                },
                method: 'POST',
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),
        deleteComplaint: builder.mutation({
            query: (comUID) => ({
                url: `mobileProrab/deleteComplaint`,
                body: {
                    comUID: comUID,
                },
                headers: {
                    token: getCookie('token'),
                },
                method: 'POST',
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),
        getUPDStatistic: builder.query({
            query: ({ startDate, endDate, selectedValues, valueType }) => ({
                url: `mobileProrab/updStatistics`,
                body: {
                    dateFrom: startDate.getTime(),
                    dateTo: endDate.getTime(),
                    selectedValues: selectedValues,
                    valueType: valueType,
                },
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getUPDList: builder.query({
            query: ({ startDate, endDate }) => ({
                url: `mobileProrab/updList`,
                body: {
                    dateFrom: startDate?.getTime(),
                    dateTo: endDate?.getTime(),
                },
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getAddresses: builder.query({
            query: (counterpartUID) => ({
                url: `mobileProrab/address`,
                method: 'POST',
                body: {
                    counterpartUID: counterpartUID,
                },
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getCounterparts: builder.query({
            query: () => ({
                url: `mobileProrab/counterparts`,
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getConsignee: builder.query({
            query: () => ({
                url: `mobileProrab/consignee`,
                method: 'POST',
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getConsigneePickupList: builder.query({
            query: (counterpartUID) => ({
                url: `mobileProrab/consigneePickupList `,
                method: 'POST',
                body: {
                    counterpartUID: counterpartUID,
                },
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getPhoneNumbers: builder.query({
            query: (addressUID) => ({
                url: `mobileProrab/phone_numbers`,
                method: 'POST',
                body: {
                    addressUID: addressUID,
                },
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getNomenclatures: builder.query({
            query: (addressUID) => ({
                url: `mobileProrab/nomenclature`,
                method: 'POST',
                body: {
                    addressUID: addressUID,
                },
                headers: {
                    token: getCookie('token'),
                },
            }),
            onQueryStarted: getCookie('token') && onQueryStartedErrorToast,
        }),

        getPing: builder.query({
            query: () => ({
                url: `mobileProrab/ping`,
                method: 'POST',
                providesTags: ['Ping'],
                headers: {
                    token: getCookie('token'),
                },
            }),
        }),

        resetPing: builder.mutation({
            query: () => ({
                url: `mobileProrab/ping`,
                method: 'POST',
                providesTags: ['Ping'],
                headers: {
                    token: getCookie('token'),
                },
            }),
        }),
    }),
});

export const { useGetRequestsQuery } = sslApi;
export const { useGetDeliveryListQuery } = sslApi;
export const { useGetStatisticQuery } = sslApi;
export const { useGetPopularNomenclaturesQuery } = sslApi;
export const { useGetBonusesQuery } = sslApi;
export const { useGetCertificatesQuery } = sslApi;
export const { useAddRequestMutation } = sslApi;
export const { useGetSettingsQuery } = sslApi;
export const { useGetPushListQuery } = sslApi;
export const { useGetAddressesQuery } = sslApi;
export const { useGetConsigneeQuery } = sslApi;
export const { useGetPingQuery } = sslApi;
export const { useGetCounterpartsQuery } = sslApi;
export const { useGetConsigneePickupListQuery } = sslApi;
export const { useGetPhoneNumbersQuery } = sslApi;
export const { useGetNomenclaturesQuery } = sslApi;
export const { useResetPingMutation } = sslApi;
export const { useGetUPDStatisticQuery } = sslApi;
export const { useGetUPDListQuery } = sslApi;
