import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../store/store';

import '../../../components/Pages/Requests/CreateRequest/ModalViews/ModalView.css';

import {
    selectContactInfo,
    selectAddress,
    selectAddressUID,
    selectNomenclature,
    selectNomenclatureUID,
    selectReceiver,
    selectReceiverUID,
    setNomenclatureMobility,
    setIsGraniteavailable,
    selectCounterpart,
    setFiller,
} from '../../../store/slices/sliceSelectedInfo';

import { setAvailebleEntarances, setIsSearchAvaileble } from '../../../store/slices/sliceRequests';
import ModalOk from '../../../content/svg/RequestsPage/ModalOk';

import { AddressDataType } from '../Interfaces/Interfaces';
import { fixPageState } from '../../../store/slices/sliceUser';

interface AddressModalProps {
    content: AddressDataType[];
    backRedirect: string;
    request: any;
}

function AddressModal({ content, backRedirect, request }: AddressModalProps) {
    const theme = localStorage.getItem('theme');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedRef = useRef<HTMLDivElement>(null);

    const addressTitle = useSelector((state: RootState) => state.selectedInfo.selectedAddress);
    const addressUID = useSelector((state: RootState) => state.selectedInfo.selectedAddressUID);
    const searchInput = useSelector((state: RootState) => state.requests.searchInput);
    const counterpart = useSelector((state: RootState) => state.selectedInfo.selectedCounterpart);

    useEffect(() => {
        if (Object.keys(content).length > 2) {
            dispatch(setIsSearchAvaileble(true));
        } else {
            dispatch(setIsSearchAvaileble(false));
        }
        if (selectedRef.current !== null) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'center',
            });
        }
    }, [content]);

    const setAddress = (
        selectedAddress: string,
        selectedAddressUID: string,
        selectedCounterpart: string,
        entarances: string[],
    ) => {
        refreshData();
        setData();

        navigate(`/${backRedirect}`, { state: { request: request, paramToFixPage: true } });

        function setData() {
            dispatch(selectAddress(selectedAddress));
            dispatch(selectAddressUID(selectedAddressUID));
            dispatch(selectCounterpart(selectedCounterpart));
            dispatch(setAvailebleEntarances(entarances));
            dispatch(fixPageState(true));
        }

        function refreshData() {
            dispatch(selectReceiver(null));
            dispatch(selectReceiverUID(null));
            dispatch(selectNomenclature(null));
            dispatch(selectNomenclatureUID(null));
            dispatch(selectContactInfo(null));
            dispatch(setIsGraniteavailable(false));
            dispatch(setNomenclatureMobility(null));
            dispatch(selectCounterpart(null));
            dispatch(setFiller(null));
            dispatch(setAvailebleEntarances(null));
        }
    };
    return (
        <div className="contentContainer">
            <div className="contentFields">
                {content
                    .filter((ticket) =>
                        ticket.title.toLowerCase().includes(searchInput.toLocaleLowerCase()),
                    )
                    .map((ticket, index) => {
                        return (
                            ticket.visible && (
                                <div
                                    onClick={() =>
                                        setAddress(
                                            ticket.title,
                                            ticket.address_uid,
                                            ticket.counterpart,
                                            ticket.entrances,
                                        )
                                    }
                                    ref={
                                        ticket.title === addressTitle &&
                                        ticket.counterpart === counterpart
                                            ? selectedRef
                                            : null
                                    }
                                    key={index}
                                    className="contentTicket"
                                >
                                    <div className="boxContaienr">
                                        <span className="phoneTxt">{ticket.counterpart}</span>
                                        <span className="contentTxt">{ticket.title}</span>
                                    </div>
                                    {ticket.title === addressTitle &&
                                    ticket.counterpart === counterpart ? (
                                        <ModalOk theme={theme} />
                                    ) : null}
                                </div>
                            )
                        );
                    })}
            </div>
        </div>
    );
}

export default AddressModal;
