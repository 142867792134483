import React, { useState } from 'react';
import axios from 'axios';
import { useInterval } from 'usehooks-ts';
import { getSHA256Hash } from 'boring-webcrypto-sha256';

import { useNavigate } from 'react-router-dom';

import blackLogo from '../../../content/png/erkon black.png';

import './LoginByPass.css';
import { createCookie } from '../../Entities/Lib/CookieWork';

function LoginByPass() {
    const navigate = useNavigate();

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');

    const [isButtonActive, setIsButtonActive] = useState(true);
    const [buttonTxt, setButtonTxt] = useState('Войти');

    useInterval(() => {
        setIsButtonActive(true);
        setButtonTxt('Войти');
    }, 3000);

    localStorage.setItem('theme', 'light');

    const theme = localStorage.getItem('theme');
    document.documentElement.dataset.theme = theme!;

    async function tryVerifyPhone(
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
    ) {
        event.preventDefault();
        setIsButtonActive(false);
        setButtonTxt('Загрузка...');
        try {
            const response = await axios.post(
                'https://api-erkon.tech/mobile/hs/mobileProrab/authLP',
                {
                    login: login,
                    hash: await getSHA256Hash(pass),
                },
            );

            if (response.status === 200) {
                const data = await response.data;

                if (data.token) {
                    createCookie('token', data.token);
                    navigate('/logo');
                }
            } else {
                setIsButtonActive(true);
            }
        } catch (error) {
            console.error(error);
            setIsButtonActive(true);
            setButtonTxt('Продолжить');
        }
    }

    return (
        <div className="fullBody">
            <div className="logoPlaceholder">
                <img src={blackLogo} className="logo" alt="logo" />
            </div>

            <form style={{ width: '100%' }} onSubmit={tryVerifyPhone}>
                <div className="numberInputPlaceholder">
                    <span className="numberInputTitle">Вход</span>

                    <div className="phoneNumberInput">
                        <input
                            className="loginXd"
                            type="text"
                            placeholder="Логин"
                            value={login}
                            onChange={(event) => setLogin(event.target.value)}
                        />
                        <input
                            className="loginXd"
                            type="password"
                            placeholder="Пароль"
                            value={pass}
                            onChange={(event) => setPass(event.target.value)}
                        />
                    </div>
                </div>

                <div className="footerSendButton">
                    <div className="sendButtonPlaceholder">
                        {isButtonActive ? (
                            <button className="sendButtonActive" onClick={tryVerifyPhone}>
                                {buttonTxt}
                            </button>
                        ) : (
                            <button disabled className="sendButton" onClick={tryVerifyPhone}>
                                {buttonTxt}
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LoginByPass;
